@import "../../assets/styles/variables";
.listOrderPage{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
.listOrderPage_item{
  display: grid;
  grid-row-gap: 1rem;
  align-content: start;
}
.listOrderPage_top{
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;

  svg{
    width: 25px;
    color: $orange;
    cursor: pointer;
  }
}
.listOrderProduct_img{
  img{
    width: 100%;
    margin-top: 2rem;
  }
}
.listOrderProduct_download{
  text-decoration: none;
  color: #77C27A;
}

@media screen and (max-width: 1000px) {
  .listOrderPage{
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
}