@import "../../assets/styles/variables";
.listProductPage{
  display: grid;
  grid-template-columns: max(540px) 1fr;
  grid-column-gap: 3rem;
}
.listProductPage_table{
  display: grid;
  grid-row-gap: 1rem;
  align-content: start;
}
.listProductPage_filter{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-column-gap: 1rem;
}
.listProductPage_shopping{
  display: grid;
  align-content: start;
  grid-row-gap: 1rem;
}
.listProductPage_shopping_btns{
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 1rem;
}

.listProductPage_shopping_car{
  position: fixed;
  bottom: 2em;
  right: 2em;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 10px 1px rgb(220 220 220 / 40%);
  width: 5em;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-app;
  transition: all 0.1s ease-in;
  cursor: pointer;

  &:hover{
    background-color:#e3e3e3;
  }

  svg{
    width: 2em;
    height: 2em;
  }

  span{
    position: absolute;
    color: white;
    top: 23px;
  }
}
.listProductPage_img{
  display: grid;
  align-items: center;
  justify-content: center;

  img{
    max-width: 38em;
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .listProductPage{
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
  .makeStyles-container-18 {
    max-height: 249px !important;
  }
  .listProductPage_shopping_car{
    width: 3rem;
    height: 3rem;
    bottom: 1rem;
    right: 1rem;

    svg{
      width: 2rem;
      height: 2rem;
    }

    span{
      top: 12px;
      font-size: 13px;
    }
  }
}