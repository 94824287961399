@import "../../assets/styles/variables";
.login{
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}
.login_svg{
  display: grid;
  justify-content: center;
  align-items: center;
}
.login_svg_bg{
  display: grid;
  position: absolute;
  height: 100%;
}
.login_svg_truck{
  display: grid;
  position: relative;
  width: 21em;
}
.login_content{
  display: grid;
  align-content: center;
  grid-row-gap: 2em;
  justify-items: center;
  position: relative;

  form{
    display: grid;
    grid-row-gap: 15px;
  }
}
.login_icon{
  width: 8em;
}
.login_title{
  display: grid;
  font-size: $unit * 2;
}
.login_form{
  display: grid;
  grid-row-gap: 1em;
  justify-items: center;
}
.login_recoverPassword{
  text-decoration: none;
  color: $orange;
}

@media only screen and (max-width: 900px){
  .login{
    grid-template-columns: 1fr;
    grid-template-rows: 0px 1fr;
  }
  .login_svg_bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
  .login_svg_truck{
    display: none;
  }
  .login_form{
    background-color: white;
    padding: $unit * 2;
    border-radius: 4px;
    box-shadow: $shadow-2;
  }
}