@import "../../assets/styles/variables";
.footer{
  display: grid;
  text-align: center;
  color: white;
  background-color: $orange;
  z-index: 1;
  grid-column: 1/3;
  height: 39px;
  align-self: end;
  align-items: center;

  a{
    text-decoration: none;
    color: white;
  }
}

@media screen and (max-width: 900px){
  .footer{
    grid-column: 1/2;
  }
}