// @import '~balloon-css/src/balloon';
@import './variables';
@import './helper';
@import './library';
@import './typografy';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:focus,
button:focus,
textarea:focus,
select:focus{
  outline: none;
}
button:disabled,
button[disabled]{
  pointer-events: none;
  opacity: .5;
}
textarea {
  resize: none;
}
// @font-face{
//   font-family: 'SourceSansPro';
//   src: url(../font/SourceSansPro-Light.ttf);
//   font-weight: 300;
// }
// @font-face{
//   font-family: 'SourceSansPro';
//   src: url(../font/SourceSansPro-Regular.ttf);
//   font-weight: 400;
// }
// @font-face{
//   font-family: 'SourceSansPro';
//   src: url(../font/SourceSansPro-SemiBold.ttf);
//   font-weight: 600;
// }
// @font-face{
//   font-family: 'SourceSansPro';
//   src: url(../font/SourceSansPro-Black.ttf);
//   font-weight: 700;
// }
// @font-face{
//   font-family: 'SourceSansPro';
//   src: url(../font/SourceSansPro-Bold.ttf);
//   font-weight: 900;
// }
// @font-face{
//   font-family: 'WorkSans';
//   src: url(../font/WorkSans-ExtraBold.ttf);
// }
body{
  background-color: #f9f9f9;
}
html{
  // font-family: 'SourceSansPro';
  font-size: 16px;
}

.message{
  display: grid;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: $shadow-1;
  text-align: center;
  font-size: $unit * 1.5;

  &.error{
    color: $wine;
    background-color: $red-light;
  }

  &.success{
    background-color: #D1E7DD;
    color: #0C4128;
  }
}
h1,h2,h3,h4,h5{
  // font-family: 'WorkSans';
}

select {
  padding: .25em;
  border: 0;
  border-bottom: 1px solid currentcolor; 
  letter-spacing: .15em;
  border-radius: 0;
  display: grid;
  justify-self: start;
  height: 35px;
  &:focus, &:active {
    outline: 0;
  }
}
// external

.MuiButton-contained{
  background-color: $color-app;
  font-family: "arial";
  color: white !important; 
}

.MuiStepIcon-root.MuiStepIcon-active, .MuiStepIcon-root.MuiStepIcon-completed{
  color: $color-app;
}

.MuiStepConnector-lineVertical{
  min-height: 60px;
}

.errorPage{
  display: grid;
  grid-row-gap: $unit *4;
  justify-items: center;

  img{
    width: 300px;
  }
}

.img_empty{
  display: grid;
  justify-items: center;
  grid-row-gap: $unit * 2;
  
  img{
    max-width: 300px;
    width: 100%;
  }
}