$unit: 8px;

// brackpoints
$mobile: 576px;
$tablet: 768px;
$desktop: 1024;
$widescreen: 1200px;

// Colors
$color-app: #F69255;
$color-app-hover: #ff6c11;
$grey-light: #5c5c5c;
$grey-more-light: #d3d3d3;
$ocean: #131051;
$sea: #2b79f5;
$orange: #f99352;
$sky: #5dc0ff;
$black: #000000;
$white: #ffffff;
$red-light: #F8D7DA;
$wine: #922029;
$green: #03c303;
$green-hover: #129c12;

// Shadows
$shadow-1: 0 0 10px 1px rgba(220, 220, 220, .4);
$shadow-2:  0 8px 16px rgba(10, 10, 10, .16);
$shadow-3:  0px 11px 26px -14px rgba(0, 0, 0, .75);

//text

$xl-font-size: $unit * 2;
$lg-font-size: $unit * 1.75;
$md-font-size: $unit * 1.5;
$sm-font-size: $unit * 1.35;
$xs-font-size: $unit * 1.15;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 700;
$weight-strong: 800;