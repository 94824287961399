.modalShopping_final{
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
  margin-top: 1rem;
  align-items: center;
}
.modalShopping_final_total{
  display: grid;
  grid-row-gap: 10px;
  font-size: 21px;

  p{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    text-align: end;
  }
}
@media screen and (max-width: 450px) {
  .modalShopping_final{
    grid-auto-flow: row;
    grid-row-gap: 1rem;
    justify-items: center;
    justify-content: center;
  }
  .modalShopping_final_total{
    grid-row: 1/2;
  }
}