.modal{
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #000000b3;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.modal_content{
  background: white;
  padding: 2rem;
  position: relative;
  border-radius: 5px;
  max-width: 95vw;
  width: 100%;

  &.modal-lg{
    min-width: 52vw;
  }
}
.modal_transparent{
  background: transparent;
}
.modal_close{
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: grey;
  cursor: pointer;
  background-color: transparent;
  border: none;
}