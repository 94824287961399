.modalConfirm{
  display: grid;
  grid-row-gap: 1rem;
  text-align: center;
}
.modalConfirm_btns{
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
}