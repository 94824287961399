@import '../../assets/styles/variables';
.container{
  display: grid;
  grid-template-columns: 0.5fr 20fr 0.5fr;

  .content{
    grid-column: 2/3;
  }
}

.layout{

}
.layout_nav_content{
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
}

.layout_logout{
  color: $orange;
  cursor: pointer;
  display: grid;

  svg{
    width: 28px !important;
    height: 28px !important;
  }
}
.layout_nav{
  background-color: white;
  box-shadow: $shadow-1;
  padding: $unit 0px;
}
.layout_logo{
  img{
    width: 5em;
  }
}
.layout_content{
  padding: $unit *2 0px;
}