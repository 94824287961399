@import "../../assets/styles/variables";
.dashboardAdmin{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  max-width: 1500px;
  margin: 0 auto;
}
.dashboardAdmin_item{
  display: grid;
  grid-row-gap: 2rem;
  justify-items: center;
  border-radius: 5px;
  background-color: white;
  padding: 1rem;
  box-shadow: $shadow-1;
  text-decoration: none;

  img{
    width: 100%;
    max-height: 68vh;
  }

  h2{
    color: $orange;
  }
}

@media screen and (max-width: 900px) {
  .dashboardAdmin{
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
}