@import "../../assets/styles/variables";
.listClientPage{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
.listClientPage_item{
  display: grid;
  align-content: start;
  grid-row-gap: 1rem;
}
.listClientPage_client{}
.listClientPage_details_btns{
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 10px;
}
.listClientPage_details{
}
.listClientPage_products{
  grid-column: 1/3;
}
.listClientPage_top{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  svg{
    color: $orange;
    width: 35px;
    height: 35px;
  }
}

.listClientPage_form{
  display: grid;
  grid-row-gap: 1rem;
  justify-items: center;
}
.listClientPage_none{
  display: none;
}
.listClientPage_label{
  background-color: #66bb6a;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .listClientPage{
    grid-template-columns: 1fr;
  }
  .listClientPage_products{
    grid-column: 1/2;
  }
}

@media screen and (max-width: 500px) {
  .listClientPage_details_btns{
    grid-auto-flow: row;
    grid-row-gap: 10px;
  }
}