.button-sm{
  min-width: 25px !important;
  padding: 1px 5px !important;
}

.button-tanspatent{
  background-color: transparent !important;
  box-shadow: none !important;

  &:hover{
    box-shadow: none !important;
  }
}